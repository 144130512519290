export default {
  freemium: {
    meta: {
      title: "Watch Free Live TV in Spanish {'|'} FlixLatino",
      description: `
        Stream free live TV in Spanish with FlixLatino. Sign up now and watch thousands 
        of channels on any device. Get started for free and enjoy live TV instantly!
      `,
      keywords: `
        free live tv,
        watch free live tv,
        stream free live tv,
        watch live tv for free
      `,
    },
    hero: {
      title: `
        Watch free live TV <br />
        in Spanish
      `,
      subtitle:
        "Discover how easy it is to stream thousands of channels instantly.",
    },
    featuredChannels: {
      title: "Featured Channels",
      content: `
        Tune in to various channels in Spanish, from anywhere at any time. <br />
        Whether you’re a fan of sports, horror, or cooking, there’s a channel
        for everyone.
      `,
      cta: "Watch free TV",
    },
    supportedDevices: {
      title: "Available on All Devices",
      content:
        "Stream FlixLatino on every device, including phones, tablets, and PCs.",
      cta: "Start Watching Now",
    },
  },
};
