import en_routes from "./route_translations/en_routes";
import en_freemium from "./translations/en_freemium";
const en = {
  ...en_freemium,
  ...en_routes,
  back: "Back",
  close: "Close",
  play: "Play",
  resume: "Resume",
  content_text: "Watch {artist} on FlixLatino for only $3.99",
  load_more: "Load More",
  more_info: "More Info",
  available_on: "Available on",
  banner: {
    title: "Improve your experience",
    subtitle:
      "Download the app to enjoy all our features and exclusive content.",
    button: "Open APP",
  },
  detail_page: {
    subscribe: "Subscribe now",
    trailer: "Trailer",
    about: "About",
    country: "Country of Origin: ",
    directors: "Directed by: ",
    cast: "Cast: ",
    next_episodes: "Next Episodes",
    view_all_episodes: "View all episodes",
    episodes: "Episodes",
    similar_content: "Similar Content",
    season: "Season",
    play: "Watch Now",
    watch_and_stream_movies: "{movie} {'|'} Watch & stream movies online",
    watch_episode_alt:
      "Watch {episode} online {'|'} Stream {show} Season {season}",
    stream_tv_shows: "{title} {'|'} Stream TV shows",
    next_episode_alt:
      "Watch {show} online {'|'} Stream season {season}, {episode}",
    next_episode_title: "Season {season}, {episode}",
    no_longer_available: "This title is no longer available",
  },
  home: {
    meta: {
      title: "Browse TV Shows & Movies in Spanish Online | FlixLatino",
      description:
        "FlixLatino offers the best Spanish films and series from the Spanish-speaking world. Featuring Latino movies, Spanish movies, and family friendly Spanish-language entertainment. Discover countless hours of Spanish series, movies, and programming from your country – all ad free for just $3.99 per month. Your culture, your movies.",
    },
  },
  browse: {
    meta: {
      title: "Browse All Content",
      description:
        "Browse the largest collection of entertainment in Spanish worldwide. Sign up for a free today.",
    },
  },
  discover: {
    meta: {
      title: "Browse TV Shows & Movies in Spanish Online {'|'} FlixLatino",
      description:
        "Discover the largest collection of entertainment in Spanish worldwide. Browse TV shows and movies by country or category. Sign up for a free today.",
    },
    title: "Discover",
    subtitle: "Discover Spanish Entertainment, <br /> from around the world",
    search: "Search movies, TV Shows, Actors, Genres...",
    country: {
      meta: {
        title:
          "Discover Movies and Series in Spanish from {country} {'|'} FlixLatino",
        description:
          "Enjoy the largest collection of entertainment in Spanish. Discover movies and series from {country}. Sign up for a free today.",
      },
    },
    by_country: {
      view_all: "View all",
      content_from: "Content from {country}",
      result_count: "{count} Results",
      title: "Browse content by Country",
      subtitle:
        "Select a country of interest and find all of the top spanish movies and tv shows produced there!  ",
    },
    moods: {
      title: "Discover movies based on your mood",
      subtitle:
        "Select a mood and find all of the top spanish movies and tv shows that match your mood!  ",
      trynow: "Try now",
    },
  },
  checkout: {
    save_twenty_five: "Save 25% with an annual plan",
    pay_by_card: "Pay by card",
    locale: "en_US",
    loading: "Loading...",
    card: {
      name: "Name on Card",
      number: "Card Number",
      expiration: "Expiration Date",
      cvc: "CVC",
      postal: "Zip Code",
      cardholderName: "Cardholder Name",
    },
    mobile: {
      change_plan: "Change Plan",
      title: "Activate",
      sub_title: "Amount Due Today: ${amount}*",
      confirm: "Confirm",
      free_trial: "Free Trial",
      free_trial_dias: "7 Days Free Trial",
      smalltext:
        'By pressing the "Start Trial" button, you accept our terms and conditions and certify that you are over 18. You can cancel at any time during your free trial and you will not be charged. To cancel go to your account preferences and press "Cancel Membership"',
      condition:
        "An amount of $ 0.01 will be authorized to validate your credit card which will be returned in full within the next 24 hours",
      bullets: {
        one: "Set up payment for later",
        two: "No charge until {date}",
        three: "Total due after free trial  ${price}",
      },
      plans: {
        basic: "FlixLatino Basic",
        premium: "FlixLatino Premium",
        monthly: "Monthly",
        annual: "Annual",
      },
    },
    title: "Activate",
    title_trial: "7-Day Free Trial",
    subtitle: "Confirm and start your free 7 day trial.",
    firstname: "First Name",
    lastname: "Last Name",
    credit: "Credit/debit card number",
    exp: "Exp (MM/YY)",
    cvv: "Security Code (CVV)",
    giftcode: "Enter GiftCode",
    add_giftcode: "Add GiftCode",
    plan_details: "View plan details",
    confirm: "Confirm",
    free_trial: "Free trial",
    validating: "Validating...",
    notvalid: "Invalid code",
    willbecharged: "You will be charged on",
    sevendays: "7 days free",
    duetoday: "Due today:",
  },
  body_promo: {
    title: "1000+ hours of entertainment in Spanish",
    subtitle:
      "The most sought after Turkish series. Movies and series from Mexico, Spain, Colombia, Venezuela, Dominican Republic, Peru, and more.",
    button: "Create account for free",
  },
  register: {
    title: "Set up your account",
    subtitle:
      "Let’s start by setting up your account to access your membership! ",
    email: "Email",
    name: "Name",
    password: "Password",
    confirm_password: "Confirm your password",
    continue: "Continue",
    account: "Account",
    payment: "Payment",
    account_exists: "This E-mail address is already registered",
    password_not_match: "Passwords do not match",
    password_missing: "Password is required",
    activation: "Activate",
  },
  message: {
    hello: "hello world",
  },
  navbar: {
    trial: "Create Account",
    discover: "Discover",
    login: "Log In",
    logout: "Log Out",
    subscribe: "Subscribe now",
    currentLanguage: "EN",
    lang: {
      es: "ES",
      en: "EN",
    },
    logged: {
      movies: "Movies",
      series: "Series & Shows",
      live: "Channels",
      my_list: "My List",
      by_country: "Browse by Country",
      preferences: "Account",
      help: "Help",
    },
  },
  plans: {
    title: "Choose your plan",
    sub_title: "Just a few more steps and you’re done!",
    subtitle: "Upgrade or cancel anytime",
    cardSubtitle: "Plan Details",
    alreadySubscribed: "Current Plan",
    save: "Saves 25%",
    toggle: {
      monthly: "Monthly",
      annual: "Annual",
    },
    freePlanPopup: {
      accountCreated: "Your account has been activated successfully",
      redirectionInfo: "You will be redirected soon",
      link: "If you don’t want to wait click here",
    },
    upgradePlanPopup: {
      title: "Ready for the <br />Premium Experience",
      subtitle:
        "Our premium plan offers you exclusive content, unlimited downloads and multiple profiles.",
      okBtn: "Upgrade your plan",
    },
    downloadAppPopup: {
      title: "Improve your experience",
      subtitle:
        "Download the app to enjoy all our features and exclusive content.",
      okBtn: "Download app",
    },
    downloadAppDialog: {
      title: "Watch this channel and more in our app",
      subtitle: "",
      okBtn: "Download our free app",
    },
    bullets: [
      "Monthly Price",
      "Live Channels",
      "All movies available",
      "Devices you can watch at the same time on any platform",
      "Kids content",
      "Add-free",
      "Downloads available",
      "Binge all episodes at once",
    ],
    mostPopular: "Most Popular",
    free: {
      title: "Free with ads",
      mobileTitle: "Free with ads",
      subscribeButton: {
        monthly: "Subscribe for Free",
        annual: "Subscribe for Free",
      },
      bullets: [
        "Select amount of free channels",
        "No Premium content",
        "Ad-supported",
      ],
    },
    basic: {
      title: "Basic plan",
      mobileTitle: "Basic",
      subscribeButton: {
        monthly: "Subscribe for $3.99",
        annual: "Subscribe for $35.99",
      },
      subtitle: "Plan Details",
      bullets: ["Premium live channels", "Kids content", "Ad-free"],
    },
    premium: {
      title: "Premium plan",
      mobileTitle: "Premium",
      subscribeButton: {
        monthly: "Subscribe for $4.99",
        annual: "Subscribe for $44.99",
      },
      subtitle: "Plan Details",
      bullets: [
        "All Premium content",
        "Downloads available",
        "Binge all episodes at once",
        "Plus all Basic plan benefits",
      ],
    },
  },
  login: {
    title: "Log in",
    remember: "Remember me",
    email: "Email address",
    password: "Password",
    forgot: "Forgot your password?",
    continue: "Continue",
    donthave: "Don’t have an account?",
    create: "Create Account",
    create_new_pass: "Create new password",
    social_login_error:
      "If you have already been registered please login and link your account.",
  },
  privacy_request: {
    sending: "Submiting request",
    sent: "Your request have been sent",
    title: "Privacy Request Form",
    description:
      "This form is strictly for privacy related queries. FlixLatino reserves the right to refuse requests, in part or in whole, to the extent permitted by law, if we are unable to verify your identity, or if we cannot verify your authority to act on behalf of another person. <br/><br/>General customer service questions will not be answered if submitted by this form. If your question is not related to privacy, please send an email to support{'@'}flixlatino.com.",
    requestor: "Requestor Form",
    requestor_sub:
      "We are required to verify your identity prior to complying with your request. In order to verify your identity, please provide the following information:",
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    phone: "Phone number",
    state: "State of residence",
    select_request: "Select request",
    checkbox_label:
      "Request to delete personal information FlixLatino has collected about you.",
    checkbox_label_li_1:
      "FlixLatino does not hold financial information about its customers.",
    checkbox_label_li_2:
      "FlixLatino will decouple the data that is developed from the viewer's use of the platform, including viewing habits, from identifying personal information.",
    time_frame_text:
      "During what time frame (approximately) do you believe FlixLatino may have collected or held your personal data? ",
    explanation_text:
      "Please explain why you believe FlixLatino may hold your personal data. Describe any interactions you have had with FlixLatino that you think may help us in locating relevant data.",
    please_read: "Please read and select the applicable option below:",
    declare_same_person:
      "I declare under penalty of perjury that I am the individual whose personal information is the subject of the request.",
    declare_authorized_person:
      "I declare under penalty of perjury that I am the Authorized Agent of the individual whose personal information is the subject of the request. I understand I will be required to provide proof in writing of my status as the individual's Authorized Agent and additional information to confirm my identity.",
    certify_information:
      "By submitting this form, I hereby certify that the information entered into this form is complete, accurate, and up‐to‐date. I understand that it may be necessary for FlixLatino to verify my identity and/or the identity of the authorized agent for this request, and additional information may be requested for this purpose.",
    submit_form: "Submit Form",
    close: "Close",
    form: {
      error_first_name: "This field is required",
      error_last_name: "This field is required",
      error_email: "This field is required",
      error_state: "This field is required",
      error_phone: "This field is required",
      error_time_frame: "This field is required",
      error_explanation: "This field is required",
    },
  },
  landing: {
    miff: {
      title: "Para los amantes del buen cine",
      map_title: "Movies, series and cartoons",
    },
    pl: {
      title: "Streaming live here",
    },
    slider: {
      title: "Your culture, <br> your movies",
      emailPlaceholder: "Enter E-mail",
      startTrial: "Get Started for free",
      value_1: "Commercial free",
      value_2: "Series and movies in  Spanish",
      value_3: "Up to 4 devices at the same time",
    },
    premieres: {
      title: "Premieres",
      browse_all: "Browse all content",
    },
    exclusive_content: {
      title: "Exclusive for FlixLatino",
    },
    promo: {
      title: "Available on every favorite platform",
      featured_actors: "Featured Actors",
      featured_actors_on: "{actor} on FlixLatino",
      featured_actors_desc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi ornare sed pretium arcu id laoreet arcu, lectus non.",
      whatch_on_tv: "Watch on TV",
      whatch_on_tv_desc:
        "Watch your favorite content on your Samsung Smart TV, Roku, AppleTV, AndroidTV, Amazon FireTV, and Chromecast.",
      use_our_app: "Download our app",
      use_our_app_desc:
        "Enjoy movies, series and cartoons for the whole family, wherever you want. Download our app and start enjoying today!",
    },
    testimonials: {
      title: "Testimonials",
      member_since: "Member since {date}",
      message: "{message_en}",
    },
    our_products: {
      title: "Our Plans",
      plans: "View Plan details",
      plan_1: {
        plan: "FlixLatino Basic",
        title: "On demand content for everyone",
        description:
          "Get started with all of your favorite on-demand TV and movies for you and your whole family for only $3.99",
        button: "Subscribe for $3.99",
      },
      plan_2: {
        plan: "FlixLatino Premium",
        title: "Enjoy your favorite content wherever you are",
        description:
          "Download content for offline viewing and binge on entire season premieres.",
        button: "Subscribe now for $4.99",
      },
    },
    be_part_of: {
      title: "Be a part of FlixLatino",
      subtitle: "Subscribe and enjoy the best series and movies in Spanish",
      emailPlaceholder: "Enter E-mail",
      startTrial: "Get Started",
    },
    language: {
      current: "English",
      current_code: "en",
      other: "Spanish",
      other_code: "es",
    },
  },
  profiles: {
    add: "Add",
    add_title: "Add profile",
    whoiswatching: "Who’s watching?",
    done: "Done",
    manage: "Manage profiles",
    logout: "Log out",
    save: "Save",
    cancel: "Cancel",
    delete: "Delete Profile",
    name: "Name (Required)",
    email: "Email",
    country: "Country of origin",
    birthday: "Date of birth",
    language: "Language",
    flixKids: {
      title: "FlixLatino Kids",
      description:
        "A FlixKids profile will only play TV shows and movies carefully selected for kids, and it won't allow access to account settings.",
    },
  },
  search: {
    search: "Search",
    placeholder: "Search for movies & series",
    search_results: "Search results for",
    search_results_empty: "No results found for",
    search_results_empty_suggestion: "Try searching for a different term",
    countries: "Countries",
    categories: "Categories",
    actors: "Actors",
    genres: "Genres",
    directors: "Directors",
    deafult_title: "Recommended",
    results: "Results",
    no_results: "No results found",
  },
  preferences: {
    access_until: "You will have access until",
    membersince: "Member since",
    billing: "Billing",
    billing_date: "Your next billing date is ",
    plan_details: "Plan details",
    cancel_membership: "Cancel membership",
    change_password: "Change password",
    change_number: "Change number",
    manage_subscription: "Manage subscription",
    change_plan: "Change plan",
    current_password: "Current password",
    new_password: "New password",
    wrong_password: "Wrong password",
    password_success: "Password changed successfully",
    save_payment_method: "Save payment method",
  },
  floatingmenu: {
    browse: "Browse",
    movies: "Movies",
    series: "Series & Shows",
    live: "Channels",
    my_list: "My List",
    countries: "Browse by Country",
  },
  forgotpassword: {
    title: "Forgot password?",
    sent_title: "Email sent",
    email_required: "Email is required",
    email_sent:
      "An email has been sent to you with instructions to reset your password.",
  },
  cancel: {
    title: "Please tell us why you want to Cancel your Membership?",
    continue: "Continue cancelling",
    sub_title: "Select an option below to continue cancelling your membership.",
    reason_a: "I’m not satisfied with the content on FlixLatino",
    reason_b: "FlixLatino is too expensive for me",
    reason_c: "I don’t have time to watch FlixLatino",
    reason_d: "Too much buffering or poor video quality",
    reason_e: "Other",
    cancel_success: "Your membership has been cancelled",
  },
  link: {
    title: "Link your device",
    code: "Enter the code",
    continue: "Continue",
    success: "Your device has been linked",
  },
  meta: {
    about: {
      title: "About Us - FlixLatino",
      description:
        "FlixLatino is a subscription video on demand service (SVOD) offering the largest variety of award-winning movies, series and documentaries originally produced in Spanish. ",
    },
    movie: {
      title: "Watch {title} Online {'|'} FlixLatino",
      description:
        "Watch {title} online. Stream the full movie using your computer or mobile device and share it with friends. Join FlixLatino today.",
    },
    series: {
      title: "Watch {title} Online {'|'} FlixLatino",
      description:
        "Watch full episodes of {title} online. Stream the TV show using your computer or mobile device and share it with friends.",
    },
    episodes: {
      title: "Watch {show} Online: Season {season}, {episode} {'|'} FlixLatino",
      description:
        "Watch full episodes of {show} online. Stream season {season}, {episode} using your computer or mobile device and share it with friends today.",
    },
  },
  termsAndConditions: {
    meta: {
      title: "FlixLatino Terms of Use",
      description:
        "We are a subscription service that provides our members with access to motion pictures, television and other audio-visual content streamed over the Internet",
    },
  },
  about_us: "{en}",
  footer: {
    discover: "Discover",
    movies: "Movies",
    contactUs: "Contact Us",
    freemium: "Watch Free Live TV",
    news: "Blog",
    search: "Search",
    faq: "FAQ",
    aboutUs: "About Us",
    privacyPolicy: "Privacy Policy",
    termsAndConditions: "Terms & Conditions",
    callUs: "Call toll free at",
  },
  "Get the full experience!": "Get the full experience!",
  "Start your free trial now to get unlimited content":
    "Get Started now to get unlimited content",
  "Free Trial": "Get Started",
  Trending: "Trending",
  "Top Movies in 2024": "Top Movies in 2024",
  "Top Movies Right Now": "Top Movies Right Now",
  "Top TV Shows in 2024": "Top TV Shows in 2024",
  "Top TV Shows Right Now": "Top TV Shows Right Now",
  upgrade_section: {
    title: "Unlock The Full Experience",
    account_cancelled: "Account Cancelled",
    maximum: "You already have the premium plan",
    upgrade_plan: "Upgrade Plan",
    includes: "Upgrade now and enjoy these benefits: ",
    feature_1: "Binge all episodes at once",
    feature_2: "Download to your phone/tablet",
    feature_3: "Watch on 4 different screens",
    error_upgrade: "Error upgrading plan",
    error_message:
      "Contact support for more information about your account, Call us at +1-888-533-8395",
    upgrade_plan_title: "Are you sure you want to change your plan?",
    upgrade_plan_text:
      "You are about to change your plan to {plan}, for {price}",
    upgrade: "Upgrade",
    downgrade: "Downgrade",
    cancel: "Cancel",
  },
  affiliates_home: "Affiliates Program",
  annual_membership: "Annual Membership",
  monthly_membership: "Monthly Membership",
  watch_on_app: "Watch {title} in our app",
  download_app: "Download our free app",
};
export default en;
