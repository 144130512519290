export default {
  freemium: {
    meta: {
      title: `Ver TV en vivo gratis en español {'|'} FlixLatino`,
      description: `
        Transmite TV en vivo gratis en español con FlixLatino. 
        Regístrate ahora y mira miles de canales en cualquier dispositivo. 
        ¡Empieza gratis y disfruta de la televisión en vivo al instante!
      `,
      keywords: `
        televisión en vivo gratis,
        ver tv en vivo gratis,
        transmitir tv en vivo gratis,
        ver tv en vivo gratis
      `,
    },
    hero: {
      title: "Ver TV Gratis en Vivo en Español",
      subtitle:
        "Descubre  lo fácil que es transmitir miles de canales al instante",
    },
    featuredChannels: {
      title: "Canales destacados",
      content: `
        Mira canales en español, desde cualquier lugar y en cualquier momento. <br /> 
        Si eres fanático de los deportes, el terror o la cocina, hay un canal para todos.
      `,
      cta: "Ver TV gratis",
    },
    supportedDevices: {
      title: "Disponible en Todos los dispositivos",
      content:
        "Transmite FlixLatino en todos los dispositivos, incluidos teléfonos, tablets y PC.",
      cta: "Ver TV gratis",
    },
  },
};
