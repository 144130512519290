import es_routes from "./route_translations/es_routes";
import es_freemium from "./translations/es_freemium";
const es = {
  ...es_freemium,
  ...es_routes,
  back: "Atrás",
  close: "Cerrar",
  play: "Play",
  resume: "Reanudar",
  load_more: "Ver Más",
  more_info: "Más información",
  content_text: "Mira a {artist} en FlixLatino por solo $3.99",
  available_on: "Disponible el",
  banner: {
    title: "Mejora tu experiencia",
    subtitle:
      "Disfruta de todas las funciones y contenido exclusivo en nuestra app.",
    button: "Ir al APP",
  },
  detail_page: {
    subscribe: "Suscribirse ahora",
    trailer: "Tráiler",
    about: "Acerca de",
    country: "País de origen: ",
    directors: "Dirigido por: ",
    cast: "Elenco: ",
    next_episodes: "Próximos episodios",
    view_all_episodes: "Ver todos los episodios",
    episodes: "Episodios",
    similar_content: "Contenido Similar",
    season: "Temporada",
    play: "Reproducir",
    watch_and_stream_movies: "{movie} {'|'} Ver películas online",
    watch_episode_alt:
      "Reproducir {episode} en línea {'|'} Reproducir {show} Temporada {season}",
    stream_tv_shows: "{title} {'|'} Series de TV online",
    next_episode_alt:
      "Reproducir {show} en línea {'|'} Reproducir temporada {season}, {episode}",
    next_episode_title: "Temporada {season}, {episode}",
    no_longer_available: "Este título no está disponible",
  },
  home: {
    meta: {
      title: "Series y Películas en Español - FlixLatino - Cine en Español",
      description:
        "FlixLatino ofrece las mejores películas y series en español del mundo habla-hispano, con cine latino y entretenimiento para toda la familia. Descubre incontables horas de cine hispano, las series más recientes y programación de tu país, todo sin comerciales por tan solo 3.99 al mes. Tu cultura, tus películas.",
    },
  },
  browse: {
    meta: {
      title: "Explorar contenido",
      description:
        "Explora la colección más grande de entretenimiento en Español. Suscríbete para una prueba gratis hoy.",
    },
  },
  discover: {
    meta: {
      title: "Descubre Películas y Series en Español en Línea {'|'} FlixLatino",
      description:
        "Disfruta la colección más grande de entretenimiento en Español. Descubre las películas y series por genero o por países. Suscríbete para una prueba gratis hoy.",
    },
    title: "Descubre",
    subtitle: "Descubre entretenimiento en español <br /> de todo el mundo",
    search: "Buscar películas, series de televisión, actores, géneros...",
    country: {
      meta: {
        title:
          "Descubre Películas y Series en Español de {country} {'|'} FlixLatino",
        description:
          "Disfruta de la más grande colección de entretenimiento en Español. Descubre películas y series de {country}. Inicia tu prueba gratis hoy.",
      },
    },
    by_country: {
      view_all: "Ver todos",
      content_from: "Contenido de {country}",
      result_count: "{count} Resultados",
      title: "Navegar contenido por país",
      subtitle:
        "¡Selecciona un país de interés y encuentra todas las mejores películas y programas de televisión en español producidos allí!",
    },
    moods: {
      title: "Descubre películas según tu estado de ánimo",
      subtitle:
        "¡Selecciona un estado de ánimo y encuentra todas las mejores películas y programas de televisión en español que coincidan con tu estado de ánimo!",
      trynow: "Probar ahora",
    },
  },
  checkout: {
    save_twenty_five: "Ahorra 25% con pago anual",
    pay_by_card: "Con Tarjeta",
    locale: "es_ES",
    loading: "Conectando...",
    card: {
      name: "Nombre",
      number: "Número de tarjeta",
      expiration: "Fecha de vencimiento",
      cvc: "CVC",
      postal: "Zip Code",
      cardholderName: "Nombre del titular de la tarjeta",
    },
    mobile: {
      change_plan: "Cambiar plan",
      title: "Activar",
      sub_title: "Monto a pagar hoy: ${amount}*",
      confirm: "Confirmar",
      free_trial: "Comienza Ahora",
      free_trial_dias: "Canales en vivo Gratis",
      smalltext:
        "Al presionar el botón de “Comenzar Prueba Gratis”, aceptas nuestros términos y condiciones y certificas que eres mayor de 18 años. Puedes cancelar en cualquier momento durante tu prueba gratis y no serás cobrado. Para cancelar diríjase a las preferencias de su cuenta y presione “Cancelar Membresía”.",
      condition:
        "Se pre autorizará un monto de $0.01 para validar su tarjeta de crédito el cual será regresado en su totalidad en las próximas 24 horas.",
      bullets: {
        one: "Configurar pago para más tarde",
        two: "Gratis hasta {date}",
        three: "Monto a cobrar después de la prueba ${price}",
      },
      plans: {
        basic: "FlixLatino Básico",
        premium: "FlixLatino Premium",
        monthly: "Mensual",
        annual: "Anual",
      },
    },
    title: "Activar",
    title_trial: "Prueba 7 Días",
    subtitle: "Confirma y comienza tu prueba gratuita de 7 días.",
    firstname: "Nombre",
    lastname: "Apellido",
    credit: "Número de tarjeta de crédito/débito",
    exp: "Vencimiento (MM/AA)",
    cvv: "Código de seguridad (CVV)",
    giftcode: "Introducir código de regalo",
    add_giftcode: "Agregar código de regalo",
    plan_details: "Ver detalles del plan",
    confirm: "Confirmar",
    free_trial: "Crear cuenta",
    validating: "Validando...",
    notvalid: "Código no válido",
    willbecharged: "Se te cobrará el",
    sevendays: "7 días gratis",
    duetoday: "Pagar hoy:",
  },
  body_promo: {
    title: "Más de 1000 horas de entretenimiento en español",
    subtitle:
      "Las series turcas más populares, películas y series de México, España, Colombia, Venezuela, República Dominicana, Perú y más.",
    button: "Crear Cuenta Gratis",
  },
  register: {
    title: "Crea tu cuenta",
    subtitle: "¡Comencemos creando tu cuenta para acceder a tu membresía!",
    email: "Correo electrónico",
    name: "Nombre",
    password: "Contraseña",
    confirm_password: "Confirmar contraseña",
    continue: "Continuar",
    account: "Cuenta",
    payment: "Pago",
    account_exists: "Esta dirección de correo electrónico ya está registrada",
    password_not_match: "Las contraseñas no coinciden",
    password_missing: "Se requiere una contraseña",
    activation: "Activar",
  },
  message: {
    hello: "hola mundo",
  },
  navbar: {
    trial: "Registrarse",
    discover: "Descubre",
    login: "Iniciar sesión",
    logout: "Cerrar sesión",
    subscribe: "Suscribirse ahora",
    currentLanguage: "ES",
    lang: {
      es: "ES",
      en: "EN",
    },
    logged: {
      movies: "Peliculas",
      series: "Series",
      live: "Canales",
      my_list: "Mi Lista",
      by_country: "Por pais",
      preferences: "Cuenta",
      help: "Ayuda",
    },
  },
  plans: {
    title: "Escoge tu plan",
    sub_title: "Cámbialo o cancela cuando quieras.",
    subtitle: "Cámbialo cuando quieras",
    cardSubtitle: "Detalles del Plan",
    alreadySubscribed: "Ya estás suscrito a este plan",
    save: "Ahorra 25%",
    toggle: {
      monthly: "Mensual",
      annual: "Anual",
    },
    freePlanPopup: {
      accountCreated: "Tu cuenta ha sido activada con éxito",
      redirectionInfo: "Serás redireccionado en unos segundos",
      link: "Si no quieres esperar haz click aquí",
    },
    upgradePlanPopup: {
      title: "¿Listo para vivir la <br /> experiencia premium?",
      subtitle:
        "Nuestro plan premium te ofrece contenido exclusivo, descargas ilimitadas y múltiples perfiles.",
      okBtn: "Mejora tu plan",
    },
    downloadAppPopup: {
      title: "Mejora tu experiencia",
      subtitle:
        "Disfruta de todas las funciones y contenido exclusivo en nuestra app.",
      okBtn: "Descargar app",
    },
    downloadAppDialog: {
      title: "Mira este canal y más en nuestro app",
      subtitle: "",
      okBtn: "Descarga nuestro app gratis",
    },
    bullets: [
      "Precio mensual",
      "Canales en vivo",
      "Todas las películas disponibles",
      "Dispositivos simultáneos en cualquier plataforma",
      "Animados para niños",
      "Sin comerciales",
      "Descargas disponibles",
      "Temporadas completas al instante",
    ],
    mostPopular: "Más Popular",
    free: {
      title: "Gratis con ads",
      mobileTitle: "Gratis",
      subscribeButton: {
        monthly: "Suscríbete Gratis",
        annual: "Suscríbete Gratis",
      },
      bullets: [
        "Selección de canales gratis",
        "Sin contenido Premium",
        "Con anuncios",
      ],
    },
    basic: {
      title: "Básico",
      mobileTitle: "Básico",
      subscribeButton: {
        monthly: "Suscríbete por $3.99",
        annual: "Suscríbete por $35.99",
      },
      bullets: [
        "Canales Premium en vivo",
        "Animados para niños",
        "Libre de anuncios",
      ],
    },
    premium: {
      title: "Premium",
      mobileTitle: "Premium",
      subscribeButton: {
        monthly: "Suscríbete por $4.99",
        annual: "Suscríbete por $44.99",
      },
      bullets: [
        "Todo el contenido Premium",
        "Descargas disponibles",
        "Temporadas completas al instante",
        "Más los beneficios del plan Básico",
      ],
    },
  },
  login: {
    title: "Iniciar sesión",
    remember: "Recordarme",
    email: "Dirección de correo electrónico",
    password: "Contraseña",
    forgot: "¿Olvidaste tu contraseña?",
    continue: "Continuar",
    donthave: "¿No tienes una cuenta?",
    create: "Crear cuenta",
    create_new_pass: "Crear contraseña nueva",
    social_login_error:
      "Por favor inicie sesión y enlace su cuenta si ya esta registrado.",
  },
  privacy_request: {
    title: "Formulario de solicitud de privacidad",
    description:
      "Este formulario es estrictamente para consultas relacionadas con la privacidad. FlixLatino se reserva el derecho de rechazar solicitudes, en parte o en su totalidad, en la medida permitida por la ley, si no podemos verificar su identidad, o si no podemos verificar su autoridad para actuar en nombre de otra persona. Las preguntas generales de servicio al cliente no serán respondidas si se envían mediante este formulario. Si tu pregunta no está relacionada con la privacidad, envíe un correo electrónico a support{'@'}flixlatino.com.",
    requestor: "Formulario de solicitante",
    requestor_sub:
      "Estamos obligados a verificar su identidad antes de cumplir con su solicitud. Para verificar su identidad, por favor proporcione la siguiente información:",
    first_name: "Nombre",
    last_name: "Apellido",
    email: "Email",
    phone: "Número de teléfono",
    state: "Estado de residencia",
    select_request: "Seleccionar solicitud",
    checkbox_label:
      "Solicitud para eliminar información personal que FlixLatino ha recopilado sobre usted.",
    checkbox_label_li_1:
      "FlixLatino no posee información financiera sobre sus clientes.",
    checkbox_label_li_2:
      "FlixLatino desacoplará los datos que se desarrollen a partir del uso de la plataforma por parte del espectador, incluidos los hábitos de visualización, de la información personal de identificación.",
    time_frame_text:
      "¿Durante qué período de tiempo (aproximadamente) cree usted que FlixLatino pudo haber recopilado o retenido su información personal?",
    explanation_text:
      "Explique por qué cree que FlixLatino puede conservar sus datos personales. Describe cualquier interacción que has tenido con FlixLatino que crees que nos puede ayudar a localizar datos relevantes.",
    please_read: "Lea y seleccione la opción aplicable a continuación:",
    declare_same_person:
      "Declaro bajo pena de perjurio que soy la persona cuya información personal es objeto de la solicitud.",
    declare_authorized_person:
      "Declaro bajo pena de perjurio que soy el Agente Autorizado de la persona cuyo La información es el objeto de la solicitud. Entiendo que se me pedirá que proporcione pruebas por escrito. de mi estatus como Agente Autorizado del individuo e información adicional para confirmar mi identidad.",
    certify_information:
      "Al enviar este formulario, por la presente certifico que la información ingresada en este formulario está completa, precisa y actualizada. Entiendo que puede ser necesario que FlixLatino verifique mi identidad y/o la identidad del agente autorizado para esta solicitud, y se puede proporcionar información adicional. solicitado para este efecto.",
    submit_form: "Enviar Solicitud",
    sending: "Enviando Solicitud",
    sent: "Tu Solicitud ha sido enviada",
    close: "Cerrar",
    form: {
      error_first_name: "Este campo es requerido",
      error_last_name: "Este campo es requerido",
      error_email: "Este campo es requerido",
      error_state: "Este campo es requerido",
      error_phone: "Este campo es requerido",
      error_time_frame: "Este campo es requerido",
      error_explanation: "Este campo es requerido",
    },
  },
  landing: {
    miff: {
      title: "Para los amantes del buen cine",
      map_title: "Películas, series y animados",
    },
    pl: {
      title: "Streaming en vivo aquí",
    },
    slider: {
      title: "Tu cultura, <br> tus películas",
      emailPlaceholder: "Ingresa correo electrónico",
      startTrial: "Comienza Ahora Gratis",
      value_1: "Sin comerciales",
      value_2: "Series y películas en español",
      value_3: "Hasta 4 dispositivos al mismo tiempo",
    },
    premieres: {
      title: "Estrenos",
      browse_all: "Ver todo el contenido",
    },
    exclusive_content: {
      title: "Exclusivo para FlixLatino",
    },
    promo: {
      title: "Disponible en todas las plataformas",
      featured_actors: "Actores destacados",
      featured_actors_on: "{actor} en FlixLatino",
      featured_actors_desc:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi ornare sed pretium arcu id laoreet arcu, lectus non.",
      whatch_on_tv: "Mira en TV",
      whatch_on_tv_desc:
        "Mira tu contenido favorito en tu Samsung Smart TV, Roku, AppleTV, AndroidTV, Amazon FireTV y Chromecast.",
      use_our_app: "Descarga nuestra aplicación",
      use_our_app_desc:
        "Disfruta de películas, series y dibujos animados para toda la familia, donde quieras. ¡Descarga nuestra aplicación y comienza a disfrutar hoy mismo!",
    },
    testimonials: {
      title: "Testimonios",
      member_since: "Miembro desde {date}",
      message: "{message_es}",
    },
    our_products: {
      title: "Nuestros planes",
      plans: "Ver detalles del plan",
      plan_1: {
        plan: "FlixLatino Basic",
        title: "Contenido bajo demanda para todos",
        description:
          "Comienza con todas tus películas y programas de TV favoritos bajo demanda para ti y toda tu familia por solo $3.99",
        button: "Suscribirse por $3.99",
      },
      plan_2: {
        plan: "FlixLatino Premium",
        title: "Disfruta de tu contenido favorito donde quiera que estés",
        description:
          "Descarga contenido para ver sin conexión y maratona estrenos completos de temporadas.",
        button: "Suscribirse ahora por $4.99",
      },
    },
    be_part_of: {
      title: "Únete a FlixLatino",
      subtitle:
        "Suscríbete y disfruta de las mejores series y películas en español",
      emailPlaceholder: "Ingresa correo electrónico",
      startTrial: "Comienza ahora gratis",
    },
    language: {
      current: "Español",
      current_code: "es",
      other: "Inglés",
      other_code: "en",
    },
  },
  profiles: {
    add: "Añadir",
    add_title: "Añadir perfil",
    whoiswatching: "¿Quién está viendo?",
    done: "Hecho",
    manage: "Administrar perfiles",
    logout: "Cerrar sesión",
    save: "Guardar",
    cancel: "Cancelar",
    delete: "Eliminar perfil",
    name: "Nombre (Requerido)",
    email: "Correo electrónico",
    country: "País de origen",
    birthday: "Fecha de nacimiento",
    language: "Idioma",
    flixKids: {
      title: "FlixLatino Kids",
      description:
        "Un perfil de FlixKids solo reproducirá programas de televisión y películas cuidadosamente seleccionados para niños, y no permitirá acceder a la configuración de la cuenta.",
    },
  },
  search: {
    search: "Buscar",
    placeholder: "Buscar películas o series",
    search_results: "Resultados de búsqueda para",
    search_results_empty: "No se encontraron resultados para",
    search_results_empty_suggestion: "Prueba a buscar un término diferente",
    countries: "Países",
    categories: "Categorías",
    actors: "Actores",
    genres: "Géneros",
    directors: "Directores",
    deafult_title: "Recomendado",
    results: "Resultados",
    no_results: "No se encontraron resultados",
  },
  preferences: {
    access_until: "Tienes acceso hasta",
    membersince: "Miembro desde",
    billing: "Facturación",
    billing_date: "Tu próxima fecha de facturación es ",
    plan_details: "Detalles del plan",
    cancel_membership: "Cancelar membresía",
    change_password: "Cambiar contraseña",
    change_number: "Cambiar número",
    manage_subscription: "Administrar suscripción",
    change_plan: "Cambiar plan",
    current_password: "Contraseña actual",
    new_password: "Nueva contraseña",
    wrong_password: "Contraseña incorrecta",
    password_success: "Contraseña actualizada",
    save_payment_method: "Guardar método de pago",
  },
  floatingmenu: {
    browse: "Navega",
    movies: "Peliculas",
    series: "Series y shows",
    live: "Canales",
    my_list: "Mi lista",
    countries: "Por país",
  },
  forgotpassword: {
    title: "¿Olvidaste tu contraseña?",
    sent_title: "Correo electrónico enviado",
    email_required: "Correo electrónico requerido",
    email_sent:
      "Hemos enviado a tu dirección de correo electrónico las instrucciones para restablecer tu contraseña.",
  },
  cancel: {
    title: "Por favor, indícanos por qué deseas cancelar tu membresía.",
    sub_title:
      "Selecciona una opción a continuación para proceder con la cancelación de tu membresía.",
    reason_a: "No estoy satisfecho/a con el contenido en FlixLatino",
    reason_b: "FlixLatino es demasiado caro para mí",
    reason_c: "No tengo tiempo para ver FlixLatino",
    reason_d: "Demasiado almacenamiento en búfer o mala calidad de video",
    reason_e: "Otra",
    cancel_success: "Tu membresía ha sido cancelada",
  },
  link: {
    title: "Vincular dispositivo",
    code: "Código",
    continue: "Continuar",
    success: "Dispositivo vinculado",
  },
  meta: {
    about: {
      title: "Sobre Nosotros - FlixLatino",
      description:
        "FlixLatino es un servicio de entretenimiento en español por Internet \"Over The Top\" (OTT) en la modalidad de aplicación, que entrega contenidos bajo el sistema de 'subscription video-on-demand' (SVOD) para el mercado hispano de los Estados Unidos y Puerto Rico en todas las plataformas y productos electrónicos.",
    },
    movie: {
      title: "Ver {title} Online {'|'} FlixLatino",
      description:
        "Ver {title} online. Ver la película completa usando su computadora o dispositivo móvil y compartela con amigos. Únete a FlixLatino.",
    },
    series: {
      title: "Ver {title} Online {'|'} FlixLatino",
      description:
        "Ver {title} online. Ver la serie completa usando su computadora o dispositivo móvil y compártela con amigos.",
    },
    episodes: {
      title:
        "Ver {show} en línea: Temporada {season}, {episode} {'|'} FlixLatino",
      description:
        "Ver todos los episodios de {show} online. Ver Temporada {season}, {episode} en línea usando su computadora o dispositivo móvil y compártela con amigos.",
    },
  },
  termsAndConditions: {
    meta: {
      title: "Condiciones de uso - FlixLatino",
      description:
        "Somos un servicio por subscripción que provee acceso a películas, programas de televisión y demás contenido audio visual transmitidos a través del Internet",
    },
  },
  about_us: "{es}",
  footer: {
    discover: "Descubre",
    movies: "Películas",
    contactUs: "Contáctanos",
    freemium: "Ver TV Gratis",
    news: "Blog",
    search: "Buscar",
    faq: "FAQ",
    aboutUs: "Quiénes Somos",
    privacyPolicy: "Política de privacidad",
    termsAndConditions: "Términos y condiciones",
    callUs: "Llámenos gratis al",
  },
  "Get the full experience!": "¡Obtén la experiencia completa!",
  "Start your free trial now to get unlimited content":
    "Comienza ahora a disfrutar de tu contenido favorito",
  "Free Trial": "Comienza Ahora",
  Trending: "Tendencias",
  "Top Movies in 2024": "Mejores Películas 2024",
  "Top Movies Right Now": "Mejores Películas Ahora",
  "Top TV Shows in 2024": "Mejores Series de TV 2024",
  "Top TV Shows Right Now": "Mejores Series de TV Ahora",
  upgrade_section: {
    title: "Obtén la experiencia completa",
    title_downgrade: "Cambiar de plan",
    account_cancelled: "Cuenta Cancelada",
    maximun: "Ya tienes el plan premium",
    upgrade_plan: "Cambiar de plan",
    includes: "Cambia tu plan hoy y disfruta de estos beneficios:",
    feature_1: "Todos los episodios por adelantado (Estrenos semanales)",
    feature_2: "Descargas a tu teléfono o tablet",
    feature_3: "4 pantallas simultáneas",
    error_upgrade: "Error al cambiar de plan",
    error_message:
      "Contacta a soporte para más información sobre tu cuenta, Llámenos gratis al +1-888-533-8395",
    upgrade_plan_title: "¿Estás seguro de que deseas cambiar tu plan?",
    upgrade_plan_text: "Estas a punto de cambiar tu plan a {plan}, por {price}",
    upgrade: "Cambiar",
    downgrade: "Cambiar",
    cancel: "Cancelar",
  },
  affiliates_home: "Programa de Afiliados",
  annual_membership: "Membresía anual",
  monthly_membership: "Membresía mensual",
  watch_on_app: "Mira {title} en nuestro app",
  download_app: "Descarga nuestro app gratis",
};
export default es;
